import React, { Component, useState } from 'react'

class Produtos extends Component {     

    constructor(props) {
        super(props);
        this.state = {
          modal: false
        };
    
        this.toggle = this.toggle.bind(this);
    }
    
    toggle() {
        this.setState(prevState => ({
          modal: !prevState.modal
        }));
    }

    getNamePage() {
        document.getElementsByTagName('body')[0].className = 'produtos'
    }

    removeNamePage() {
        document.getElementsByTagName('body')[0].className = ''
    }

    componentDidMount() {
        this.getNamePage()
    }

    componentWillUnmount (){
        this.removeNamePage() 
    }

    render() {

        return(
            
            <div>

              <div className="container">
                <h1>
                   Produtos
                </h1>

                <h2>
                   SGV - Sistema de Gerenciamento de Vendas
                </h2>

                <p>
                    O Sistema de Gerenciamento de Vendas, também conhecido como SGV, foi desenvolvido
                    especificamente para setores de vendas de produtos e/ou prestação de serviços como
                    comércios locais (Vidraçarias/Casas de Ração/Mercearias/Supermercados, etc).
                    É um software que abrange todas as rotinas administrativas, desde a abertura de ordens de
                    serviços e vendas (OSV’s) até o faturamento junto ao cliente.<br/><br/>
                    <strong>Módulos disponíveis:</strong>
                </p>

                <div className="row">
                    <div className="col-md-6">
                        <ul>
                            <li>Emissão de Nota Fiscal (Danfe)</li>
                            <li>Emissão de Cupom Fiscal</li>
                            <li>Emissão Nota fiscal Eletrônica de Consumidor</li>
                            <li>Faturamento</li>
                            <li>Contas a Receber</li>
                            <li>Contas a Pagar</li>
                            <li>Estoque Mercadorias</li>
                            <li>Ficha Técnica</li>
                            <li>Emissão de Boletos (Padrão FEBRABAN)</li>
                            <li>Sintegra</li>
                            <li>Sped Fiscal</li>
                            <li>Relatórios Diversos</li>
                        </ul>
                    </div>
                </div>

                
              </div>

            </div>


        )
    }
}

export default Produtos