import React, { Component, useState, Fragment } from 'react'
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter, Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import Sga1 from '../img/sga1_min.jpg'
import Sga2 from '../img/sga1.jpg'



class Sga extends Component {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle.bind(this);

    this.state = {
      modal: false,
      isOpen: false
    };

  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
      isOpen: !this.state.isOpen
    }));
  }

  getNamePage() {
    document.getElementsByTagName('body')[0].className = 'produtos'
  }

  removeNamePage() {
    document.getElementsByTagName('body')[0].className = ''
  }

  componentDidMount() {
    this.getNamePage()
  }

  componentWillUnmount() {
    this.removeNamePage()
  }

  render() {

    return (

      <div>

        <div className="container">
          <h1>
            Produtos
          </h1>

          <h2>
            SGA - Sistema de Gerenciamento de Atividades
          </h2>

          <p>
            O Sistema de Gerenciamento de Atividades, também conhecido como SGA, foi desenvolvido
            especificamente para o setor automotivo. <br/><br/>
            É um software que abrange todas as rotinas administrativas do segmento, desde a abertura de
            ordens de serviços e vendas (OSV’s) até o faturamento junto ao cliente.
          </p>

          {/*<p>
            Desenvolvido em plataforma WINDOWS, banco de dados relacional e linguagem de última
            geração, possui uma interface com usuário de fácil interação, auxilia os setores
            administrativo, comercial, técnico, financeiro e estoque em suas rotinas diárias,
            processando as informações com segurança. 
            <br/><br/>
            Por ser flexível em sua forma de pesquisas,
            filtros e exportações de registros, auxilia os setores comercial e técnicos nas
            rotinas de apuração de garantias, histórico de ordens de serviços e vendas, setor
            financeiros quanto aos registro de débitos junto ao contas a receber, cheques pre-datados
            e cheques devolvidos e estoque quanto à cotações de preços, estoque mínimo e outras
            rotinas e vários relatórios gerenciais.<br/><br/>
            Para maiores informações, entre em contato no telefone <strong>(31) 3201-2308</strong>.
          </p>*/}

          <div className="row">
            <div className="col-md-6">
              <ul>
                <li>Faturamento</li>
                <li>Emissão Nota Fiscal (DANFE)</li>
                <li>Emissão Cupom Fiscal</li>
                <li>Emissão Nota Fiscal Eletrônica Consumidor</li>
                <li>Contas a Receber</li>
                <li>Contas a Pagar</li>
                <li>Tesouraria</li>
                <li>Comissões</li>
                <li>Conectividade com a Base Nacional Cronotacógrafos (Inmetro)</li>
                <li>Sped Fiscal</li>
                <li>Sintegra</li>
                <li>Estoque de Mercadorias</li>
                <li>Garantia</li>
                <li>Relatórios diversos em conformidade com Edital do Inmetro</li>
                <li>Integração com o Whatsapp</li>
              </ul>
            </div>

            <div className="col-md-6">

              <ul className="fotos-produtos">
                <li>
                  <Button onClick={this.toggle}>
                    <img src={Sga1} alt="Software automotivo" className="img-fluid" />
                  </Button>
                </li>
                {/*<li>
                  <Button onClick={this.toggle2}>
                    <img src={Sga1} alt="Software automotivo" className="img-fluid" />
                  </Button>
                </li>
                <li>
                  <Button onClick={this.toggle2}>
                    <img src={Sga1} alt="Software automotivo" className="img-fluid" />
                  </Button>
                </li>
                <li>
                  <Button onClick={this.toggle2}>
                    <img src={Sga1} alt="Software automotivo" className="img-fluid" />
                  </Button>
                </li>
                <li>
                  <Button onClick={this.toggle2}>
                    <img src={Sga1} alt="Software automotivo" className="img-fluid" />
                  </Button>
                </li>
                <li>
                  <Button onClick={this.toggle2}>
                    <img src={Sga1} alt="Software automotivo" className="img-fluid" />
                  </Button>
                </li>*/}
              </ul>
          </div>
          </div>       


        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}><strong>SGA</strong></ModalHeader>
          <ModalBody>
            <img src={Sga2} alt="Software automotivo" className="img-fluid" />
          </ModalBody>
        </Modal>

      </div>


    )
  }
}

export default Sga