import React from 'react'
import { Router, Route, Redirect, hashHistory } from 'react-router'

import Home from '../home/home'
import QuemSomos from '../quem-somos/quem-somos'
import Produtos from '../produtos/produtos'
import Sga from '../produtos/sga'
import Sgd from '../produtos/sgd'
import Sgv from '../produtos/sgv'
import Servicos from '../servicos/servicos'
import Suporte from '../suporte/suporte'
import Contato from '../contato/contato'

export default props => (
    <Router history={hashHistory}>
        <Route path='/home' component={Home} />
        <Route path='/quem-somos' component={QuemSomos} />
        <Route path='/produtos' component={Produtos} />
        <Route path='/produtos/sga' component={Sga} />
        <Route path='/produtos/sgd' component={Sgd} />
        <Route path='/produtos/sgv' component={Sgv} />
        <Route path='/servicos' component={Servicos} />
        <Route path='/suporte' component={Suporte} />
        <Route path='/contato' component={Contato} />
        <Redirect from='*' to='/home' />
    </Router>
)
