import React, { Component } from 'react'

export default class QuemSomos extends React.Component {

    componentDidMount() {
        document.getElementsByTagName('body')[0].className = 'quem-somos'
    }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].className = '';
    }
    
    render() {

        return (

            <div className="container">
                <h3 className="title">
                    <span>Quem somos</span>
                </h3>                
                <div className="row">
                    <div className="col-md-12">
                        <p>
                            Desde 1986 no mercado, a Canadá Informática LTDA combina conhecimento, procedimento,
                            estratégia e tecnologia em seus desenvolvimentos, possibilitando aos seus clientes através de
                            softwares, administrar suas rotinas operacionais nos setores: comercial, técnico e financeiro,
                            minimizando possíveis perdas decorrentes de processos ineficientes.
                        </p>
                        <p>
                            Presente no segmento automotivo de Cronotacografo, Car Service e Truck Service e também no
                            segmento de Distribuição de Produtos, a Canadá Informática acompanha os avanços
                            tecnológicos e necessidades do mercado proporcionando aos seus clientes modernidade,
                            segurança nas informações, tecnologia e uma constante inovação de seus softwares.
                            <br/><br/>
                        </p>
                       
                    </div>
                    <div className="col-md-4">
                        <h4>
                            Missão
                        </h4>
                        <p>
                            Garantir a implementação e treinamento da melhor solução de gestão para o plano de negócio de nossos parceiros por meio de práticas em conformidade com a segurança dos dados, tecnologia atualizada e ética profissional.
                        </p>
                    </div>
                    <div className="col-md-4">
                        <h4>
                            Visão
                        </h4>
                        <p>
                            Superar sempre as expectativas de nossos clientes e parceiros através de soluções inteligentes, criativas e economicamente viáveis.
                        </p>
                    </div>
                    <div className="col-md-4">
                        <h4>
                            Valores
                        </h4>
                        <p>
                            Transparência em nossas ações e relações. Respeito às pessoas e suas necessidades. Buscar sempre aprendizagem e crescimento, planejar para alcançar os melhores resultados. Objetividade e clareza em nossos trabalhos.
                        </p>
                    </div>
                </div>
            </div>

        )
    }

}
