import React, { Component, useState, Fragment } from 'react'
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter, Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

class Sgd extends Component {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle.bind(this);

    this.state = {
      modal: false,
      isOpen: false
    };

  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
      isOpen: !this.state.isOpen
    }));
  }

  getNamePage() {
    document.getElementsByTagName('body')[0].className = 'produtos'
  }

  removeNamePage() {
    document.getElementsByTagName('body')[0].className = ''
  }

  componentDidMount() {
    this.getNamePage()
  }

  componentWillUnmount() {
    this.removeNamePage()
  }

  render() {

    return (

      <div>

        <div className="container">
          <h1>
            Produtos
          </h1>

          <h2>
            SGD - Sistema de Gerenciamento de Distribuição de Produtos
          </h2>

          <p>
            O Sistema de Gerenciamento de Distribuição de Produtos, também conhecido como SGD, foi
            desenvolvido especificamente para setores de distribuição produtos distribuidoras de produtos.
            <br/><br/>
            <strong>Módulos disponíveis:</strong>
          </p>

          <div className="row">
            <div className="col-md-6">
              <ul>
                <li>Emissão de Nota Fiscal</li>
                <li>Contas a Receber</li>
                <li>Contas a Pagar</li>
                <li>Emissão de Comprovante de Entrega</li>
                <li>Emissão Boletos Bancários Padrão FEBRABAN</li>
                <li>Faturamento</li>
                <li>Relatórios Diversos</li>
                <li>Manifesto de Carga</li>
                <li>Consumo de Clientes</li>
                <li>Acerto de Carga pós venda</li>
                <li>Sintegra</li>
              </ul>
            </div>
          </div>

        </div>

      </div>

    )
  }
}

export default Sgd