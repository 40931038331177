import 'modules/bootstrap/dist/css/bootstrap.min.css'
import '../scss/style.scss'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faKey, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

import { Button } from 'reactstrap'

import React from 'react'
import Header from '../template/header'
import Footer from '../template/footer'

import Routes from './routes'

library.add(faEnvelope, faKey, faAngleDoubleRight);

export default props => (
    <div>
        <Header />        
        <Routes />    
        <Footer />
    </div>
)