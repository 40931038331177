import React, { Fragment, Component } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Container
} from 'reactstrap';
import { Link, Router, Route, Redirect, hashHistory } from 'react-router'

import Logo from '../img/logo-new.png'

class Cabecalho extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        return <Fragment>
            <header>
                <Navbar color="light" light expand="lg" sticky="top">
                    <Container>
                        <NavbarBrand href="/#/home"><img src={Logo} alt="Canada Informatica" /></NavbarBrand>
                        <NavbarToggler onClick={this.toggle} />
                        <Collapse isOpen={this.state.isOpen} navbar>
                            <Nav className="ml-auto" navbar>

                                <NavItem>
                                    <NavLink href="/#/quem-somos">Quem somos</NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink href="/#/servicos">Serviços</NavLink>
                                </NavItem>

                                <UncontrolledDropdown nav inNavbar>

                                    <DropdownToggle nav caret>
                                        Produtos
                                    </DropdownToggle>

                                    <DropdownMenu right>

                                        <DropdownItem tag={Link} href="/#/produtos/sga">
                                            SGA - Gerenciamento de Atividades
                                        </DropdownItem>

                                        <DropdownItem tag={Link} href="/#/produtos/sgd">
                                            SGD - Sistema de Gerenciamento de Distribuição
                                        </DropdownItem>

                                        <DropdownItem tag={Link} href="/#/produtos/sgv">
                                            SGV - Sistema de Gerenciamento de Vendas
                                        </DropdownItem>

                                    </DropdownMenu>

                                </UncontrolledDropdown>

                                <NavItem>
                                    <NavLink href="/#/contato">Contato</NavLink>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </Container>
                </Navbar>

                <div className="banner">
                    <h1>
                        <span>Soluções integradas</span> multiusuários<br /> para sua empresa
                    </h1>
                    <a href="#/produtos" className="btn btn-primary">
                        SAIBA MAIS
                    </a>
                </div>

            </header>
        </Fragment>
    }
}

export default Cabecalho