import React, { Component } from 'react'

export default class Servicos extends React.Component {

    componentDidMount() {
        document.getElementsByTagName('body')[0].className = 'servicos'
    }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].className = '';
    }

    render() {        

        return (

            <div className="container servicos">

                <h1>
                    Serviços
                </h1>

                <h2>
                    Análise e Leituras de Discos de Tacógrafos
                </h2>

                <p>
                    <strong>O que é Leitura?</strong>
                    <br/><br/>
                    A leitura do disco diagrama é a transcrição do gráfico do disco para uma descrição literal em forma de relatório. Todos os registros que estiverem em desacordo com os parâmetros definidos pela empresa serão apontados em laudo técnico.
                    A análise desses registros oferece uma radiografia do comportamento e desempenho tanto do veículo quanto do seu motorista, permitindo identificar possíveis falhas em relação aos parâmetros estabelecidos pela empresa (excesso de velocidade, descumprimento do itinerário, paradas não programadas, etc..)
                    <br/><br/>
                    As informações colhidas pelos tacógrafos são exatas, porém, para um entendimento perfeito e melhor aproveitamento dessas informações, é indispensável uma analise técnica dos discos diagrama.
                    A partir da definição de parâmetros que atendam a legislação de transito e os interesses da empresa, a análise correta das informações obtidas nas gravações possibilitará obter um melhor aproveitamento dos veículos, tacógrafos e motoristas e, consequentemente, uma considerável economia.
                    <br/><br/>
                    Para isso a Canadá Informática coloca à disposição de seus clientes uma equipe de profissionais especializados, com formação técnica em leitura de disco diagrama, aptos a gerar relatórios padronizados ou personalizados conforme a necessidade da empresa.
                    Confeccionamos também laudos de análises de discos de tacógrafos para avaliação de sinistros.
                    <br/><br/>
                </p>

                <h2>
                    VDO - Fleet Tracking Service
                </h2>

                <p>
                    <strong>O que é?</strong>
                    <br/><br/>
                    Desenvolvido pela VDO, empresa pioneira em tecnologia automotiva, o que garante total confiabilidade e segurança dos dados e informações geradas pela solução, o VDO - Fleet Tracking Service, proporciona diversos serviços de controle para operações diárias. Trata-se de uma ferramenta que oferece serviço de Telemetria e Rastreamento Veicular, oferencendo ao usuário/cliente um contolre completo de Gestão da Frota com dados atualizados em tempo real.
                    <br/><br/>
                    Para maiores informações, entre em contato ou <a href="https://www.fleet.vdo.com.br/solu%C3%A7%C3%B5es/vdo-fts-fleet-tracking-service/" target="_blank">clique aqui</a>
                </p>

                <h2>
                    VDO - On Board
                </h2>

                <p>
                    <strong>O que é?</strong>
                    <br/><br/>
                    Trata-se de um serviço de gerenciamento de frotas disponiblizado em plataforma Web, onde pode ser acessado atraves de qualquer dispositivo conectado a Internet. Alem da facilidade de acesso a plataforma é disponibilizado para o cliente, varios relatorios que auxiliam os setores estrategicos da empresa em tomadas de decisões e na criação de planos de ação para melhorias em processos operacionais.
                    <br/><br/>
                    <strong>Como Funciona?</strong>
                    <br/><br/>
                    O Tacógrafo Digital BVDR, produto vendido e distribuido pela VDO, é responsável por gerar e enviar os dados operacionais via Pen Drive ou módulo de telemetria, para a plataforma web. Após os dados serem recepcionados na plataforma, o usuário tem diversas informações operacionais de seus veiculos, podendo ser personalizadas de acordo com suas necessidades.
                    <br/><br/>
                    <strong>Quais são os beneficios?</strong>                    
                </p>

                <ul>
                    <li>Armazenamento em Nuvem (Cloud) dos dados operacionais;</li>
                    <li>Informações Customizadas para cada tipo de veiculo dentro de sua frota;</li>
                    <li>Redução de custos para o frotista, em relação ao disco diagrama;</li>
                    <li>Precisão segundo a segundo das informações</li>
                </ul>

                <p>
                    Para maiores informações, entre em contato no telefone (31) 3201-2308.
                </p>

            </div>

        )
    }

}
