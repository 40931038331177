import React, { Component, useState } from 'react'

class Contato extends Component {

    constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle.bind(this);

    this.state = {
        modal: false,
        isOpen: false
    };

    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
            isOpen: !this.state.isOpen
        }));
    }

    getNamePage() {
        document.getElementsByTagName('body')[0].className = 'contato'
    }

    removeNamePage() {
        document.getElementsByTagName('body')[0].className = ''
    }

    componentDidMount() {
        this.getNamePage()
    }

    componentWillUnmount() {
        this.removeNamePage()
    }

    render() {

        return (
    
          <div>

               <div className="container contato">
                    <h3>
                        <span>Contato</span>
                    </h3>
                    <p>
                        Entre em contato conosco para esclarecimentos de dúvidas,<br/> 
                        suporte técnico ou solicitar versão de demonstração de nossos softwares.     
                        <br/><br/>
                        <strong>Telefones:</strong><br/>
                        (31) 2510-8742 / (31) 3201-2308 / (31) 3567-2308.
                        <br/><br/>
                        <strong>Whatsapp:</strong><br/>
                        (31) 99961-7510
                        <br/><br/>
                        <strong>Skype:</strong><br/>
                        canada.informáticaFinanceiro                    
                    </p>
                  
                </div>

          </div>
        )
    }
}

export default Contato