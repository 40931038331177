import React, { Component } from 'react'

export default class Footer extends React.Component{
    render() {
       return (
            <footer>                
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <ul>
                                <li>
                                    <a href="/#/quem-somos">
                                        Quem somos
                                    </a>                                    
                                </li>
                                <li>
                                    <a href="/#/produtos">
                                        Produtos
                                    </a>                                    
                                </li>
                                <li>
                                    <a href="/#/servicos">
                                        Serviços
                                    </a>                                     
                                </li>
                                <li>
                                    <a href="/#/contato">
                                        Contato
                                    </a>                                    
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3">
                            <p>
                                <strong>ENDEREÇO: </strong> <br/>
                                Rua Curitiba, 778 - Sala 1207, Centro <br/> Belo Horizonte/MG 
                                <br/>CEP: 30170 120
                            </p>                        
                        </div>
                        <div className="col-lg-3">
                            <p>
                                <strong>TELEFONES:</strong> <br/>
                                (31) 2510-8742 | (31) 3201-2308 <br/> (31) 3567-2308
                            </p>
                            <p>
                                <strong>WHATSSAPP: </strong><br/>
                                (31) 99961-7510
                            </p>                                                    
                        </div>
                        <div className="col-lg-3">
                            <p>
                                <strong>SKYPE: </strong><br/>
                                canada.informáticaFinanceiro
                            </p>    
                            <p>
                                <strong>HORÁRIO DE FUNCIONAMENTO:</strong><br/>
                                Segunda a Sexta 08 as 18<br/>
                                Sábado 08 as 12
                            </p>
                        </div>
                        <div className="col-lg-12">
                            <hr/>
                            <p className="copy">
                                Copyright @ 2018 <strong>Canada Informatica</strong>. Todos os direitos reservados
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
 }
 
