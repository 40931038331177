import React, { Component } from 'react'

export default class Suporte extends React.Component {

    componentDidMount() {
        document.getElementsByTagName('body')[0].className = 'suporte'
    }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].className = '';
    }

    render() {

        return (

            <div className="container contato">
                <h3>
                    <span>Suporte</span>
                </h3>
               
                <p>
                    Soluções de manutenção e prevenção dos seus recursos de TI, oferecendo serviços como: outsourcing em TI, suporte de TI e manutenção de softwares e hardwares.
                    <br/><br/>
                    Com uma equipe de profissionais altamente capacitados e recursos de última geração, a Canada garante a tranquilidade que a sua empresa precisa para focar no core business.
                </p>

            </div>

        )
    }

}
