import React, { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Software from '../img/software.png'
import Software2 from '../img/software2.png'
import Suporte from '../img/suporte.jpg'

export default class Home extends React.Component {

    componentDidMount() {
        document.getElementsByTagName('body')[0].className = 'home'
    }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].className = '';
    }

    render() {

        return (
            <div>    
                <section className="home sobre container">
                    <h2>
                        <span>Canadá</span> Informática
                    </h2>
                    <p>
                        Desde 1986 no mercado, a Canadá Informática Ltda combina conhecimento, procedimento, estratégia e tecnologia em seus desenvolvimentos, possibilitando aos seus clientes através de softwares, administrar suas rotinas operacionais nos setores: comercial, técnico e financeiro, minimizando possíveis perdas decorrentes de processos ineficientes.
                        <br/><br/>
                        Presente no segmento automotivo de Cronotacografo, Car Service e Truck Service e também no segmento de Distribuição de Produtos, a Canadá Informática acompanha os avanços tecnológicos e necessidades do mercado proporcionando aos seus clientes modernidade, segurança nas informações, tecnologia e uma constante inovação de seus softwares.
                    </p>
                </section>

                <section className="home destaque-software">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <h3>
                                    <span>Soluções </span> Integradas
                                </h3>
                                <p>
                                    Sistema de gerenciamento de atividades, sistema de distribuição, sistema de controle de associados, sistema de gerenciamento de atividades.
                                </p>
                                <a href="#/produtos" class="btn-saibam-mais">
                                    saiba mais 
                                    <FontAwesomeIcon 
                                        icon="angle-double-right"
                                        color="#dd2121"
                                    />
                                </a>
                            </div>
                            <div className="col-lg-5">
                                <img src={Software} alt="Software automotivo" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="home destaque-suporte">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6">
                                <img src={Suporte} className="img-fluid" alt="Suporte aos Clientes"/>
                            </div>
                            <div className="col-lg-5">
                                <h3>
                                    <span>Suporte</span> Técnico
                                </h3>
                                <p>
                                    Soluções de manutenção e prevenção dos seus recursos de TI, oferecendo serviços como: outsourcing em TI, suporte de TI e manutenção de softwares e hardwares.
                                    <br/><br/>
                                    Com uma equipe de profissionais altamente capacitados e recursos de última geração, a Canada garante a tranquilidade que a sua empresa precisa para focar no core business.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="home servicos">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <img src={Software2} alt="Software automotivo" className="img-fluid" />
                            </div>
                            <div className="col-lg-7">
                                <h3>
                                    <span>Serviços</span> Especializados 
                                </h3>
                                <ul>
                                    <li>
                                        Análise e Leituras de Discos de Tacógrafos
                                    </li>
                                    <li>
                                        Consultoria e Desenvolvimento
                                    </li>
                                    <li>
                                        Treinamento - LDD
                                    </li>
                                    <li>
                                        Treinamento - VDO ON BOARD
                                    </li>                            
                                </ul>
                                <a href="#/servicos" class="btn-saibam-mais">
                                    saiba mais 
                                    <FontAwesomeIcon 
                                        icon="angle-double-right"
                                        color="#dd2121"
                                    />
                                </a>
                            </div>                    
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

